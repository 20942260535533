import React from "react";

function About(){
  return (
    <div className="page">
        <div className="title">
            <h2>About</h2>
        </div>
        <div className="about">
            <p>
                Hi 👋 I'm Ritvik Garimella
                <br/><br/>
                I’m a first year Masters student majoring in Computer science in the <a href="https://sc.edu/" target="_blank" rel="noopener noreferrer" style={{textDecoration:"none", color:"#727272"}}><b>University of South Carolina</b></a>. 
                <br/><br/>
                I'm also working as a <b>Research Assistant</b> at the <a href="https://aiisc.ai/" target="_blank" rel="noopener noreferrer" style={{textDecoration:"none", color:"#727272"}}>Artificial Intelligence Institute (AIISC)</a> under the guidance of <a href="https://amit.aiisc.ai/" target="_blank" rel="noopener noreferrer" style={{textDecoration:"none", color:"#727272"}}><b>Dr. Amit Sheth</b></a>.
                <br/><br/>
                My work centers on developing custom, compact, value-aligned, and agile AI models through <a href="https://en.wikipedia.org/wiki/Neuro-symbolic_AI" target="_blank" rel="noopener noreferrer" style={{textDecoration:"none", color:"#727272"}}>NeuroSymbolic</a> approaches, with a focus on creating affordable, accessible, and <a href="https://en.wikipedia.org/wiki/Human%E2%80%93computer_interaction" target="_blank" rel="noopener noreferrer" style={{textDecoration:"none",color:"#727272"}}>Human-Centric Applications</a> that enhance efficiency, drive innovation, and contribute to social good.                
                <br/><br/>
                Beyond research, I serve as a <b>Lab Assistant</b> for CSCE101 and CSCE102, where I help students learn programming fundamentals using Python and explore practical applications of programming with HTML, CSS, and JavaScript.                
                <br/><br/>
                In addition to my academic endeavors, I hold the position of <b>Secretary General</b> for the Alumni Association (AlmaConnect) at NIT Andhra, where I earned my undergraduate degree in computer science.
                <br/><br/>
                In my free time, I like to play badminton 🏸 , hike 🥾 or watch documentaries 🎥.
                <br/><br/>
                <a style={{textDecoration:'underline'}}><b>Top Skills</b></a> : <b>Artificial Intelligence</b> • <b>Full Stack Development</b> • <b>Human Computer Interaction</b>
                <br/><br/>
                Feel free to reach out by dropping an <a target="_blank" rel="noopener noreferrer" href="mailto:ritvikg@email.sc.edu" style={{textDecoration:'none',color:'#727272'}}><b>email</b></a> or connecting on <a target="_blank" rel="noopener noreferrer" href = 'https://www.linkedin.com/in/ritvikg/' style={{textDecoration:'none',color:'#727272'}}><b>LinkedIn</b></a> to explore potential collaborations or just to chat about the fascinating world of AI!
            </p>
        </div>

    </div>
    
  );
};

export default About;
