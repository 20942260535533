import React from 'react';
import img1 from '../../Media/profile4.jpg';
import img2 from '../../Media/profile5.jpg';
import img3 from '../../Media/profile3.jpg';
import News_Data from '../../Data/news.json';



function Home() {
    return (
      <>
      <div className='profile-card'>
        <div className='profile-content'>
        <h3>About Me</h3>
        <hr/>
            <p>
                I'm a Master's student in Computer Science at <a href="https://sc.edu/" target="_blank" rel="noopener noreferrer" style={{textDecoration:"none", color:"#727272"}}><b>UofSC</b></a> and a Research Assistant at <a href="https://aiisc.ai/" target="_blank" rel="noopener noreferrer" style={{textDecoration:"none", color:"#727272"}}><b>AIISC</b></a> under <a href="https://amit.aiisc.ai/" target="_blank" rel="noopener noreferrer" style={{textDecoration:"none", color:"#727272"}}><b>Dr. Amit Sheth</b></a>. 
                <br/><br/>
                My research focuses on Neuro-Symbolic AI to develop compact, value-aligned models for human-centric applications.
                <br/><br/>
                <b>Top Skills</b> : Artificial Intelligence, Full Stack Development, Human Computer Interaction
                <br/><br/>
                I also assist in teaching programming courses and serve as Secretary General of the NIT Andhra Alumni Association.
                <br/><br/>
                <a href="#/about" style={{textDecoration:"none", color:"#727272"}}> Know More &gt;</a>
                </p>
        </div>
        <img src={img3} className='profile-image' alt='Profile'/>
      </div>

      <div id="home-middle-div" className='profile-card'>
        <img src={img2} className='profile-image' alt='Profile'/>
        <div className='profile-card-content'>
            <h3>Recent News</h3>
            <hr/>
            {News_Data.News.map((news, index) => (
              <div key={index} className='home-item'>
                <p><b>{news.date}:</b><a href={news.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" , color:"#0B58CA" }}> {news.title}</a> ({news.organization}), {news.description}</p>        
              </div>
            ))}
        </div>
      </div>


      <div id="home-last-div" className='profile-card'>
        <div className='profile-card-content'>
          <p>
          <h3>Contact & More</h3>
          <hr/>
          Feel free to reach out via <a href='mailto:ritvik916@gmail.com' target="_blank" rel="noopener noreferrer" style={{textDecoration:'none'}}><b>Email</b></a> or connect on <a target="_blank" rel="noopener noreferrer" href = 'https://www.linkedin.com/in/ritvikg/' style={{textDecoration:'none'}}><b>LinkedIn </b></a> 
          to explore potential collaborations or if you wanna chat about the fascinating world of AI!
          <br/><br/>
          <b>Some Quick links</b>
          <ul>
            <a href="#/about" style={{textDecoration:"none", color:"#727272"}}>🔗 Know more about me &gt;</a>
            <br/>
            <a href="#/cv" style={{textDecoration:"none", color:"#727272"}}>🔗 Curriculum Vitae &gt;</a>
            <br/>
            <a href="#/projects" style={{textDecoration:"none", color:"#727272"}}>🔗 Select Projects &gt;</a>
            <br/>
            <a href="#/publications" style={{textDecoration:"none", color:"#727272"}}>🔗 Publications &gt;</a>
            <br/>
          </ul>
          <b>Socials</b>
          <ul>
            <a href="https://www.linkedin.com/in/ritvikg/" style={{textDecoration:"none", color:"#727272"}}>LinkedIn &gt;</a>
            <br/>
            <a href="https://github.com/Ritvik-G" style={{textDecoration:"none", color:"#727272"}}>GitHub &gt;</a>
            <br/>
            <a href="https://scholar.google.com/citations?user=Qoo3fZIAAAAJ" style={{textDecoration:"none", color:"#727272"}}>Google Scholar &gt;</a>
            <br/>
            <a href="https://orcid.org/0009-0004-7472-4690" style={{textDecoration:"none", color:"#727272"}}>Orcid &gt;</a>
            <br/>
          </ul>
          </p>
        </div>
        <img src={img1} className='profile-image' alt='Profile'/>
      </div>
      </>
    );
  }
  export default Home;